/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.com/docs/browser-apis/
 */

 import "firebase/auth"
 import "firebase/firestore"
 // import "firebase/analytics"
 
 const addScript = url => {
 
     const script = document.createElement("script");
     script.id = 'le-script';
     script.src = url;
 
     if (document.querySelector('#le-script')) {
         document.body.removeChild(document.querySelector('#le-script'));
     }
 
     document.body.appendChild(script);
 }
 
 export const onPreRouteUpdate = ({ }) => {
     addScript("/assets/js/main.js");
 }

//  export const onRouteUpdate = () => {
//     navigator.serviceWorker.register('/sw.js').then(reg => {
//       reg.update()
//     })
//   }
 
 export { wrapPageElement, wrapRootElement } from './gatsby-ssr';