import React from 'react';
import "firebase/auth"
import "firebase/firestore"
// import "firebase/analytics"

import i18n from './src/i18next';
import { I18nextProvider } from 'react-i18next';
import { PageContextProvider } from './src/pageContext';

/**
 * Wrap all pages with a Translation provider and set the language on SSR time
 */
export const wrapRootElement = ({ element }) => {
  return <I18nextProvider i18n={i18n}>{element}</I18nextProvider>;
};

/**
 * Wrap all pages with a Translation provider and set the language on SSR time
 */
export const wrapPageElement = ({ element, props }) => {
  return <PageContextProvider pageContext={props.pageContext}>{element}</PageContextProvider>;
};


export const onRenderBody = ({ pathname, setHeadComponents, setPostBodyComponents }) => {

    const host = '/';

    setHeadComponents([
        <link rel="stylesheet" href={host + "assets/css/plugins/bootstrap.min.css"} />,
        <link rel="stylesheet" href={host + "assets/css/plugins/font-awesome.min.css"} />,
        <link rel="stylesheet" href={host + "assets/css/plugins/swiper.min.css"} />,
        <link rel="stylesheet" href={host + "assets/css/plugins/fancybox.min.css"} />,
        <link rel="stylesheet" href={host + "assets/css/style.css"} />,
        <link rel="stylesheet" href={host + "assets/css/customize.css"} />
    ]);
    setPostBodyComponents([
        <script src={host + "assets/js/plugins/jquery.min.js"} />,
        <script src={host + "assets/js/plugins/anime.min.js"} />,
        <script src={host + "assets/js/plugins/swiper.min.js"} />,
        <script src={host + "assets/js/plugins/progressbar.min.js"} />,
        <script src={host + "assets/js/plugins/smooth-scrollbar.min.js"} />,
        <script src={host + "assets/js/plugins/overscroll.min.js"} />,
        <script src={host + "assets/js/plugins/typing.min.js"} />,
        <script src={host + "assets/js/plugins/isotope.min.js"} />,
        <script src={host + "assets/js/plugins/fancybox.min.js"} />,
        <script src={host + "assets/js/plugins/swup.min.js"} />
    ]);
}