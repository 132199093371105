import ContactInfoContainerEN from './locales/en/ContactInfoContainer.json'
import ContactInfoContainerFR from './locales/fr/ContactInfoContainer.json'

import EducationContainerEN from './locales/en/EducationContainer.json'
import EducationContainerFR from './locales/fr/EducationContainer.json'

import ExperienceContainerEN from './locales/en/ExperienceContainer.json'
import ExperienceContainerFR from './locales/fr/ExperienceContainer.json'

import NewsletterContainerEN from './locales/en/NewsletterContainer.json'
import NewsletterContainerFR from './locales/fr/NewsletterContainer.json'

import RecommandationsContainerEN from './locales/en/RecommandationsContainer.json'
import RecommandationsContainerFR from './locales/fr/RecommandationsContainer.json'

import ServicesContainerEN from './locales/en/ServicesContainer.json'
import ServicesContainerFR from './locales/fr/ServicesContainer.json'

// import WorksContainerEN from './locales/en/WorksContainer.json'
// import WorksContainerFR from './locales/fr/WorksContainer.json'

// import FooterSectionEN from './locales/en/FooterSection.json'
// import FooterSectionFR from './locales/fr/FooterSection.json'

import MenuSectionEN from './locales/en/MenuSection.json'
import MenuSectionFR from './locales/fr/MenuSection.json'

// import HistoryPageEN from './locales/en/HistoryPage.json'
// import HistoryPageFR from './locales/fr/HistoryPage.json'

import IndexPageEN from './locales/en/IndexPage.json'
import IndexPageFR from './locales/fr/IndexPage.json'

import ProjectPageEN from './locales/en/ProjectPage.json'
import ProjectPageFR from './locales/fr/ProjectPage.json'

import WorksPageEN from './locales/en/WorksPage.json'
import WorksPageFR from './locales/fr/WorksPage.json'

import InfoBarEN from './locales/en/InfoBar.json'
import InfoBarFR from './locales/fr/InfoBar.json'


import HeaderContainerEN from './locales/en/HeaderContainer.json'
import HeaderContainerFR from './locales/fr/HeaderContainer.json'

// import LanguageSwitcherEN from './locales/en/LanguageSwitcher.json'
// import LanguageSwitcherFR from './locales/fr/LanguageSwitcher.json'

const siteMetadata = {
    en: {
        title: "",
        description: "",
    },
    fr: {
        title: "",
        description: "",
    }
};

export default {
    en: {
        headerContainer: HeaderContainerEN,
        languageSwitcher: {},
        contactInfoContainer: ContactInfoContainerEN,
        educationContainer: EducationContainerEN,
        experienceContainer: ExperienceContainerEN,
        footerSection: {},
        historyPage: {},
        indexPage: IndexPageEN,
        infoBar: InfoBarEN,
        menuSection: MenuSectionEN,
        newsletterContainer: NewsletterContainerEN,
        projectPage: ProjectPageEN,
        recommandationsContainer: RecommandationsContainerEN,
        servicesContainer: ServicesContainerEN,
        worksContainer: {},
        worksPage: WorksPageEN,
        siteMetadata: siteMetadata.en
    },
    fr: {
        headerContainer: HeaderContainerFR,
        languageSwitcher: {},
        contactInfoContainer: ContactInfoContainerFR,
        educationContainer: EducationContainerFR,
        experienceContainer: ExperienceContainerFR,
        footerSection: {},
        historyPage: {},
        indexPage: IndexPageFR,
        infoBar: InfoBarFR,
        menuSection: MenuSectionFR,
        newsletterContainer: NewsletterContainerFR,
        projectPage: ProjectPageFR,
        recommandationsContainer: RecommandationsContainerFR,
        servicesContainer: ServicesContainerFR,
        worksContainer: {},
        worksPage: WorksPageFR,
        siteMetadata: siteMetadata.fr
    }
}